import React, { useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Link } from '@21vek/admin-front-components';
import { logout } from '../../lib';
import { AppContext } from '../../App';
import { Header } from '../../components/Header';
import { useStyles } from './forbiddenScreenStyles';

import forbiddenImg from '../../assets/Forbidden.svg';

export const ForbiddenScreen = () => {
  const classes = useStyles();
  const { screens: {
    names: screenNames,
    showScreen,
    hideScreen
  }, setUserInfo } = useContext(AppContext);

  const handleExitClick = async () => {
    showScreen(screenNames.loading,  { text: 'Выход из сервиса…' });

    try {
      await logout({ setUserInfo, sendLogoutRequest: true });

      hideScreen();
    } catch (e) {
      showScreen(screenNames.sww);
    }
  };

  return (
    <div className="page pageError">
      <Header withGradientLine={false} />

      <main className="pageError__content forbidden">
        <section className="imageSection">
          <img src={forbiddenImg} alt="Нет доступа" />
        </section>

        <Typography
          classes={{
            root: classes.heading,
          }}
          variant="h5"
        >
          Нет доступа к сервису
        </Typography>

        <Typography
          classes={{
            root: classes.rootTypographyBody,
          }}
          variant="body1"
        >
          У вас пока нет доступа ни к одному из разделов сервиса. Напишите в поддержку, чтобы вам предоставили доступ.
        </Typography>

        <Box mt="32px" mb="20px">
          <Link href="mailto:dev@21vek.by">
            Написать в поддержку
          </Link>
        </Box>

        <Link component="button" onClick={handleExitClick}>Выйти</Link>
      </main>
    </div>
    );
};
