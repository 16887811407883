import { FC } from 'react';

import SvgIcon, { SvgIconProps } from '../Svg/SvgIcon';

export const PickUpPointIcon24: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    icon={
      <>
        <path d="M6.25924 4.53333C5.85521 4.53333 5.48632 4.76038 5.31415 5.12589C4.91601 5.97112 4.22305 7.5208 4.04349 8.42793C4.01505 8.54732 4 8.6719 4 8.8C4 9.68366 4.71634 10.4 5.6 10.4C6.48366 10.4 7.2 9.68366 7.2 8.8C7.2 9.68366 7.91634 10.4 8.8 10.4C9.68366 10.4 10.4 9.68366 10.4 8.8C10.4 9.68366 11.1163 10.4 12 10.4C12.8837 10.4 13.6 9.68366 13.6 8.8C13.6 9.68366 14.3163 10.4 15.2 10.4C16.0837 10.4 16.8 9.68366 16.8 8.8C16.8 9.68366 17.5163 10.4 18.4 10.4C19.2837 10.4 20 9.68366 20 8.8C20 8.6719 19.9849 8.54732 19.9565 8.42793C19.7769 7.5208 19.084 5.97112 18.6858 5.12589C18.5137 4.76038 18.1448 4.53333 17.7408 4.53333H6.25924Z" fill="currentColor"/>
        <path d="M5.6 12.5333C5.6 11.9442 6.07756 11.4667 6.66667 11.4667H17.3333C17.9224 11.4667 18.4 11.9442 18.4 12.5333V18.4C18.4 18.9891 17.9224 19.4667 17.3333 19.4667H14.6667C14.0776 19.4667 13.6 18.9891 13.6 18.4V16.2667C13.6 15.9721 13.3612 15.7333 13.0667 15.7333H10.9333C10.6388 15.7333 10.4 15.9721 10.4 16.2667V18.4C10.4 18.9891 9.92244 19.4667 9.33333 19.4667H6.66667C6.07756 19.4667 5.6 18.9891 5.6 18.4V12.5333Z" fill="currentColor"/>
      </>
    }
  />
);
