import jwtDecode from 'jwt-decode';
import { LogoutParams } from '@21vek/admin-front-common';

import { removeFromStorage, TOKEN_KEY, USERNAME_KEY } from './storage';
import { removeFromSessionStorage, UNAUTHORIZED_USER_URL } from './sessionStorage';
import { logoutRequest } from './api';

export const parseToken = (token: string): { token: string, error: unknown } => {
  try {
    const decoded = jwtDecode(token);

    return { token, ...decoded };
  } catch (error) {
    return { token, error };
  }
};

export const logout = async (params?: LogoutParams): Promise<void> => {
  if (params?.sendLogoutRequest) {
    await logoutRequest();
  }

  removeFromStorage(TOKEN_KEY);
  removeFromStorage(USERNAME_KEY);
  removeFromSessionStorage(UNAUTHORIZED_USER_URL);
  params?.setUserInfo?.(null);
  window.location.href = '/login';
};
