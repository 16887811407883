import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  disabled: {
    '&.MuiButton-containedPrimary': {
      backgroundColor: ({ isLoading, disabled }) =>
        isLoading && !disabled ? theme.palette.primary.light : null,
      color: ({ isLoading, disabled }) =>
        isLoading && !disabled ? theme.palette.common.white : null,
      '& $progressWrapper': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: ({ isLoading, disabled }) =>
        isLoading && !disabled ? theme.palette.secondary.light : null,
      color: ({ isLoading, disabled }) =>
        isLoading && !disabled ? theme.palette.common.white : null,
      '& $progressWrapper': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '&.MuiButton-outlined': {
      borderColor: ({ isLoading, disabled }) =>
        isLoading || disabled ? `rgba(0, 0, 0, 0.23)` : null,
      backgroundColor: ({ isLoading, disabled }) =>
        isLoading && !disabled ? theme.palette.grey[200] : null,
      color: ({ isLoading, disabled }) =>
        isLoading && !disabled ? 'transparent' : null,
      '& $progressWrapper': {
        color: theme.palette.text.primary,
      },
    },
  },
  progressWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'currentColor',
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
  },
}));
