import { useField } from 'formik';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import startOfDay from 'date-fns/startOfDay';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  notchedOutlineSize: {
    fontSize: '1.1rem',
  },
  iconButtonRoot: {
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.primary.light,
    },
    legendLabelled: {
      '& > span': {
        paddingRight: 22,
        paddingLeft: 2,
      },
    },
  },
  iconButtonSizeSmall: {
    '& svg': {
      width: '1.05rem',
    },
  },
}));

interface KeyboardDateFieldProps {
  label: string;
  format: string;
  shrink: boolean;
  name: string;
  onChange?: (date: Date | null) => void;
  formik?: boolean;
  value?: Date | null;
}

const KeyboardDateField: FC<KeyboardDateFieldProps> = ({
  shrink = true,
  format = 'dd.MM.yyyy',
  onChange,
  value = null,
  formik = true,
  ...rest
}) => {
  const classes = useStyles();
  const commonProps: Partial<KeyboardDatePickerProps> = {
    disableToolbar: true,
    fullWidth: true,
    format,
    inputVariant: 'outlined',
    variant: 'inline',
    InputLabelProps: { shrink },
    InputProps: {
      classes: {
        notchedOutline: classes.notchedOutlineSize,
      },
    },
    KeyboardButtonProps: {
      size: 'small',
      edge: 'end',
      classes: {
        root: classes.iconButtonRoot,
        sizeSmall: classes.iconButtonSizeSmall,
      },
    },
  };

  if (formik) {
    const [field, meta, form] = useField(rest);

    const handleChange = (date: Date | null) => {
      const newValue = date instanceof Date ? startOfDay(date) : null;
      form.setValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    };

    return (
      <KeyboardDatePicker
        {...commonProps}
        error={Boolean(meta.error)}
        helperText={meta.error}
        {...field}
        onChange={handleChange}
        value={field.value || null}
        {...rest}
      />
    );
  }

  return (
    <KeyboardDatePicker
      {...commonProps}
      onChange={onChange || (() => undefined)}
      value={value}
      {...rest}
    />
  );
};

export default KeyboardDateField;
