import { createContext, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../App';
import { logout } from '../../lib';

const ApiErrorContext = createContext({ setErrorStatusCode: null });

function ApiErrorProvider({ children }) {
  const [errorStatusCode, setErrorStatusCode] = useState();
  const history = useHistory();
  const {
    screens: { names: screenNames, showScreen },
    setUserInfo,
  } = useContext(AppContext);

  useEffect(() => {
    return history.listen(() => setErrorStatusCode(null));
  }, [history]);

  useEffect(() => {
    if (!errorStatusCode) {
      return;
    }

    switch (errorStatusCode) {
      case 401:
        logout({ setUserInfo });
        break;
      case 404:
        showScreen(screenNames.notFound);
      case 422:
        break;
      default:
        showScreen(screenNames.sww);
        break;
    }
  }, [errorStatusCode]);

  const contextPayload = useMemo(() => ({ setErrorStatusCode }), []);

  return (
    <ApiErrorContext.Provider value={contextPayload}>
      {children}
    </ApiErrorContext.Provider>
  );
}

ApiErrorContext.displayName = 'ApiErrorContext';

export { ApiErrorContext, ApiErrorProvider };
