import React, { FC, ReactNode, CSSProperties } from 'react';
import { FixedSizeList, VariableSizeList } from 'react-window';

const ITEM_HEIGHT = 32;
const MAX_HEIGHT = ITEM_HEIGHT * 7;

interface VirtualizedMenuListProps {
  items: ReactNode[];
  width: number | string;
  height?: number;
  rowHeights?: number[];
  listClassName?: string;
}

const VirtualizedMenuList: FC<VirtualizedMenuListProps> = ({
  listClassName,
  items = [],
  width,
  rowHeights,
  height = MAX_HEIGHT,
}) => {
  const virtualizedRow = ({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) => (
    <div style={style} key={index}>
      {items[index]}
    </div>
  );

  const listProps = {
    width,
    height: Number.isInteger(height) ? height : MAX_HEIGHT,
    className: listClassName,
    itemCount: items.length,
  };

  return rowHeights ? (
    <VariableSizeList {...listProps} itemSize={(index) => rowHeights[index]}>
      {virtualizedRow}
    </VariableSizeList>
  ) : (
    <FixedSizeList {...listProps} itemSize={ITEM_HEIGHT}>
      {virtualizedRow}
    </FixedSizeList>
  );
};

VirtualizedMenuList.displayName = 'VirtualizedMenuList';

export default VirtualizedMenuList;
