import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import noDataImg from '../assets/NoData.svg';
import noSearchImg from '../assets/NoSearch.svg';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noDataText: {
    marginTop: '20px',
  },
  noSearchText: {
    marginTop: '4px',
  },
}));

/**
 *   Компонент заглушка для случаев когда данные отсутствуют
 */
export const NoData = ({ className, text, isNoSearch }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, className)}>
      <Box textAlign="center">
        {isNoSearch ? (
          <>
            <img src={noSearchImg} width={24} height={24} alt="" />
            <Typography className={classes.noSearchText} color="textSecondary">
              {text}
            </Typography>
          </>
        ) : (
          <>
            <img src={noDataImg} width={68} height={48} alt="" />
            <Typography className={classes.noDataText} color="textSecondary">
              {text}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

NoData.propTypes = {
  /**
   * Дополнительный класс для компонента
   */
  className: PropTypes.string,
  /**
   * Текст, отображающийся внизу компонента
   */
  text: PropTypes.string,
  /**
   * В зависимости от значения будет меняться изображение над текстом
   */
  isNoSearch: PropTypes.bool,
};

NoData.defaultProps = {
  text: 'Пока нет ни одной записи',
  isNoSearch: false,
};
