import React from 'react';
import PropTypes from 'prop-types';
import { Link as MaterialLink } from '@material-ui/core';

import { useStyles } from './linkStyles';

/**
 * Компонент Link - ссылка, написанная на основе MaterialLink из <b>Material UI</b>.
 */
export const Link = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialLink
      classes={{
        root: classes.root,
      }}
      underline="none"
      variant="body1"
      {...props}
    >
      {children}
    </MaterialLink>
  );
};

Link.propTypes = {
  /**
   * содержимое ссылки
   */
  children: PropTypes.node,
};
