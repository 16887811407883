import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';

import { useStyles } from './inputStyles';

export interface InputBaseProps {
  inputClasses?: Record<string, string>;
  inputProps?: Record<string, never>;
  maxLength?: number;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  label?: React.ReactNode;
  helperText?: string;
  error?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (
    {
      maxLength,
      multiline = false,
      inputProps,
      rows = 2,
      inputClasses,
      rowsMax = 2,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <TextField
        ref={ref}
        fullWidth
        variant="outlined"
        minRows={rows}
        maxRows={rowsMax}
        multiline={multiline}
        classes={{
          root: classes.multilineInput,
          ...inputClasses,
        }}
        inputProps={{ maxLength, ...inputProps }}
        InputLabelProps={{
          shrink: true,
          style: { pointerEvents: 'auto' },
        }}
        {...props}
      />
    );
  }
);
