import { FC, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import cn from 'classnames';

import { ButtonLoading } from '../ButtonLoading';

import styles from './ModalBody.module.scss';


export interface ClassNamesModalBody {
  bodyWrapper?: string;
  bodyTitleWrapper?: string;
  bodyBtnWrapper?: string;
  bodyContentWrapper?: string;
}

export enum SizeModal {
  default = 'default',
  medium = 'medium',
}

export interface ModalBodyProps {
  onClose: () => void;
  title?: ReactNode;
  closeButtonText?: ReactNode;
  otherButton?: ReactNode;
  classNames?: ClassNamesModalBody;
  sizeModal?: keyof typeof SizeModal;
}


export const ModalBody: FC<ModalBodyProps> = ({
  children,
  title,
  closeButtonText,
  onClose,
  otherButton,
  classNames,
  sizeModal = SizeModal.default,
}) => {
  return (
    <div className={cn(styles.bodyWrapper, classNames?.bodyWrapper, {
      [styles.bodyWrapperMedium]: SizeModal.medium === sizeModal,
    })}>
      {title && (
        <Typography
          className={cn(styles.bodyTitleWrapper, classNames?.bodyTitleWrapper)}
          variant="h6"
        >
          {title}
        </Typography>
      )}
      <div className={cn(styles.bodyContentWrapper, classNames?.bodyContentWrapper)}>
        {children}
      </div>
      <div className={cn(styles.bodyBtnWrapper, classNames?.bodyBtnWrapper)}>
        <ButtonLoading
          onClick={onClose}
          variant="outlined"
          color="default"
          children={closeButtonText}
        />
        {otherButton}
      </div>
    </div>
  );
};
