export interface SheetData {
  key: symbol;
  close: () => void;
}

export class SideSheetService {
  private sheets: SheetData[] = [];

  closeAll = (): void => {
    this.sheets.forEach(({ close }: SheetData) => {
      close();
    });

    this.sheets = [];
  };

  isFirstSheet = (symbol: symbol): boolean => {
    return Boolean(this.sheets.length && this.sheets[0]?.key === symbol);
  };

  remove = (symbol: symbol): void => {
    this.sheets = this.sheets.filter(({ key }) => key !== symbol);
  };

  push = (sheet: SheetData): void => {
    this.sheets.push(sheet);
  };
}

export const sideSheetService = new SideSheetService();
