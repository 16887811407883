import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ArrowRightIcon } from '../Icons';

const useStyles = makeStyles((theme) => ({
  separator: {
    marginLeft: 0,
    marginRight: 0,
    color: theme.palette.text.primary,
  },
  li: {
    '& > a': {
      display: 'block',
    },
    '&:hover > a': {
      color: theme.palette.primary.light,
    },
  },
}));

const Breadcrumbs = ({ className, breadcrumbs, onClick }) => {
  const classes = useStyles();

  return (
    <MaterialBreadcrumbs
      className={className}
      classes={{ separator: classes.separator, li: classes.li }}
      separator={<ArrowRightIcon />}
    >
      {breadcrumbs.links.map((link) => (
        <Link
          key={link.to}
          component={RouterLink}
          to={link.to}
          color="textPrimary"
          variant="body2"
          underline="none"
          onClick={(event) => onClick(event, link)}
        >
          {link.title}
        </Link>
      ))}

      <Typography variant="body2">{breadcrumbs.current}</Typography>
    </MaterialBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  /**
   *  Дополнительный класс для компонента
   */
  className: PropTypes.string,
  /**
   * объект состоящий из `current` - названия текущей страницы current и массива `links` из объектов, содержащих
   *  `to (string)` — путь к странице.
   *  `title (string)` — название ссылки.
   */
  breadcrumbs: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    current: PropTypes.string.isRequired,
  }),
  /**
   *  Функция, вызывающаяся при клике
   */
  onClick: PropTypes.func,
};

Breadcrumbs.defaultProps = {
  className: null,
  onClick: () => undefined,
};

export default memo(Breadcrumbs);
