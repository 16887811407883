import React from 'react';
import PropTypes from 'prop-types';
import { TableCell as MaterialTableCell, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useStyles } from './tableStyles';
import cellRenderers from './cellRenderers';

function TableCellEmpty() {
  return <Typography color="textSecondary">—</Typography>;
}

const TableCell = ({ value, renderer, row, tooltipValue, link, maxWidth, onClick }) => {
  const classes = useStyles();
  const isEmpty = !value;

  const Cell = renderer({
    value,
    tooltipValue,
    classNameWrapped: classes.tableCellTextContent,
    row,
    onClick,
  });

  return (
    <MaterialTableCell
      style={{ maxWidth }}
      classes={{
        root: classes.tableCell,
        head: classes.tableCellHead,
        body: classes.tableCellBody,
      }}
      component="div"
    >
      {isEmpty ? <TableCellEmpty /> : link ? <Link to={link} onClick={e => e.stopPropagation()} className={classes.tableLink}>{Cell}</Link> : Cell}
    </MaterialTableCell>
  );
};

TableCell.propTypes = {
  value: PropTypes.node,
  renderer: PropTypes.func,
  row: PropTypes.object,
  tooltipValue: PropTypes.string,
  onClick: PropTypes.func,
};

TableCell.defaultProps = {
  renderer: cellRenderers.base,
};

export default TableCell;
