import { FC } from 'react';
import cn from "classnames";
import { Typography, Tooltip } from '@material-ui/core';

import styles from './CircleStatus.module.scss';

export enum ColorsCircleStatus {
  yellow = 'yellow',
  green = 'green',
  pink = 'pink',
  blue = 'blue',
  gray = 'gray',
  grayOutline = 'grayOutline',
  magenta = 'magenta',
  lightBlue = 'lightBlue',
  purple = 'purple',
}

export enum ColorsTextCircleStatus {
  black = 'black',
  green = 'green',
  pink = 'pink',
  yellow = 'yellow',
  gray = 'gray',
  blue = 'blue',
}

interface ClassNames {
  wrapper?: string;
  status?: string;
  text?: string;
  tooltip?: string;
}

interface CircleStatusProps {
  text?: string;
  color?: keyof typeof ColorsCircleStatus;
  colorText?: keyof typeof ColorsTextCircleStatus;
  tooltip?: string;
  classNames?: ClassNames;
}

/**
 * Компонент Status. Использует `<Tooltip>` и `<Typography>` из <b>Material UI</b>. Используется для отображения статуса.
 */
export const CircleStatus: FC<CircleStatusProps> = ({
  text = '',
  color = ColorsCircleStatus.green,
  colorText = ColorsTextCircleStatus.black,
  tooltip = '',
  classNames,
}) => {
  return (
    <Tooltip title={tooltip}>
      <div className={cn(styles.wrapper, classNames?.wrapper)}>
        <div
          className={cn(styles.status, styles[`${color}Status`], classNames?.status)}
        />
        {text && (
          <Typography
            className={cn(styles[`${colorText}Text`], classNames?.text)}
          >
            {text}
          </Typography>)}
      </div>
    </Tooltip>
  );
};
