import { makeStyles } from '@material-ui/core/styles';

type styleArgs = {
  isLoading?: boolean;
  disabled?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px 16px 8px 4px',
  },
  containedSizeSmall: {
    padding: '4px 12px 4px 4px',
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 2,
  },
  endIcon: {
    marginLeft: 2,
    marginRight: 0,
  },
  outlined: {
    padding: '7px 15px 7px 3px',
  },
  outlinedSizeSmall: {
    padding: '3px 11px 3px 3px',
  },
  toggled: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[400],
    '&$outlined': {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  disabled: {
    '&.MuiButton-containedPrimary': {
      backgroundColor: ({ isLoading, disabled }: styleArgs) =>
        isLoading && !disabled ? theme.palette.primary.light : null,
      color: ({ isLoading, disabled }: styleArgs) =>
        isLoading && !disabled ? theme.palette.common.white : null,
      '& $progressWrapper': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: ({ isLoading, disabled }: styleArgs) =>
        isLoading && !disabled ? theme.palette.secondary.light : null,
      color: ({ isLoading, disabled }: styleArgs) =>
        isLoading && !disabled ? theme.palette.common.white : null,
      '& $progressWrapper': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '&.MuiButton-outlined': {
      borderColor: ({ isLoading, disabled }: styleArgs) =>
        isLoading || disabled ? `rgba(0, 0, 0, 0.23)` : null,
      backgroundColor: ({ isLoading, disabled }: styleArgs) =>
        isLoading && !disabled ? theme.palette.grey[200] : null,
      color: ({ isLoading, disabled }: styleArgs) =>
        isLoading && !disabled ? 'transparent' : null,
      '& $progressWrapper': {
        color: theme.palette.text.primary,
      },
    },
  },
  progressWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'currentColor',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    borderRadius: theme.shape.borderRadius,
  },
}));
